<template>
  <v-dialog
    v-model="isVisibleDialog"
    persistent
    max-width="700px"
  >
    <v-container fluid>
      <div class="text-center">
        <span class="text-h3">{{ $t(title) }}</span>
      </div>
    </v-container>
    <div>
      <terminals-form
        type="edit"
        :read-only="isPermission('terminals.view')"
        :item="itemWithLatLong"
        :loading="isLoading"
        @onclose="closeForm"
        @onsave="updateItem"
      />
    </div>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  components: {
    TerminalsForm: () => import("../utils/TerminalsForm.vue"),
  },
  props: {
    isVisibleDialog: Boolean,
    toggleEdit: {
      type: Function,
      default() {
        return void 0;
      },
    },
    item: {
      type: Object,
      default() {
        return {};
      },
    },
  },

  data() {
    return {
      tab: 0,
      isLoading: false,
    };
  },
  computed: {
    ...mapGetters({
      authUser: "getUser",
    }),

    itemWithLatLong() {
      if (!this.item || typeof this.item !== "object") return this.item;

      // Replace "N/A" values with null
      const transformedItem = Object.fromEntries(
        Object.entries(this.item).map(([key, value]) => [key, value === "N/A" ? null : value])
      );

      // If location exists, parse it into latitude and longitude
      if (transformedItem.location) {
        const [latitude, longitude] = transformedItem.location.split(",").map(coord => parseFloat(coord.trim()));
        return {
          ...transformedItem,
          latitude,
          longitude,
        };
      }

      return transformedItem;
    },


    title() {
      return "edit";
    },
  },
  mounted() {
    if (this.item.location) {
      const [latitude, longitude] = this.item.location.split(",").map(coord => parseFloat(coord.trim()));
      this.item.latitude = latitude;
      this.item.longitude = longitude;
    }
  },
  methods: {
    isPermission(val) {
      return this.$admin.can(val);
    },
    async updateItem(row) {
      this.isLoading = true;
      await this.$store
        .dispatch("terminal/updateTerminal", row)
        .then(() => {
          this.$store.dispatch(
            "alerts/success",
            this.$t("updated_successfully")
          );
          this.isLoading = false;
          this.toggleEdit();
        })
        .catch((error) => {
          this.$store.dispatch("alerts/error", error.response?.data?.message);
          this.isLoading = false;
        });
    },
    closeForm() {
      this.$store.commit("csmsLocations/SET_LOCATION_IMG_LIST", []);
      this.toggleEdit();
    },
  },
};
</script>